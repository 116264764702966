// Contact Form
// ------------
.contact-form {
  .form-set {
    label {
      display: none;
    }

    input {
      background-color: $light-gray;
      padding: 30px 20px;
      border: 0;
      box-shadow: none;
      font-size: 16px;

      &::placeholder {
        color: $xdkblue;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }
    textarea {
      min-height: 150px;
      background-color: $light-gray;
      padding: 20px;
      border: 0;
      box-shadow: none;
      font-size: 16px;
      resize: vertical;

      &::placeholder {
        color: $xdkblue;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }
  }

  .form-messages {
    display: inline-block;
    margin-left: 20px;

    &.success {
      color: $success-color;
    }

    &.error {
      color: $alert-color;
    }
  }
}